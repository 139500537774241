<template>
<full-page title="About Our Runs">
<p>First and foremost, individuals taking part must take responsibility for their 
own safety and well-being during group runs.
</p>
<h2>What to expect</h2>
<p>A run, primarily on trail, out in the Surrey Hills at an easy pace. The distance is usually somewhere between 10 
and 30 miles.  The route is defined and published beforehand including distance, start and end points and a time to meet.
</p>
<p>
The idea is for the group to run together and this means running at the pace of the slowest runner. You are 
welcome to break away and press on ahead but then you are on your own!
</p>
<p>
We usually aim to finish somewhere with coffee and cake.
</p>
<h2>What to bring</h2>
<p>
    <ul>
    <li>Trail shoes and running kit suitable for the weather</li>
    <li>A backpack or race vest to carry kit, food and water</li>
    <li>Enough food and water to keep you going for the full duration of the run</li>
    <li>Waterproofs</li>
    <li>A spare base layer</li>
    <li>A mobile phone</li>
    <li>Some cash and a bank card</li>
    <li>A map and compass or GPS device</li>
    </ul>
</p>
<p>
If using a GPS device then it's a good idea to load the route onto it.
</p>
<p>
A couple of useful apps for your phone are OS Maps and Uber.</p>
<h2>What if I get lost?</h2>
<p>
If, for whatever reason, you are separated from the group the first thing to do is try to figure out where 
you are using your map, GPS or phone.  Next try to phone the run leader  and establish if it will be possible 
to meet up somewhere. If you can't get in contact or rejoining the group isn't going to be possible then figure
out how to get to the end point. Options include running your own route to the end, running to a nearby village 
and arranging for someone to collect you, or running to the nearest road and using a taxi.
</p>
<h2>What if I'm injured?</h2>
<p>
If you aren't able to run or walk to the nearest road then now is the time to call the emergency services.  
If you are still with the group then one or more of the group will remain with you until you have been 
safely recovered.  If the injury is less serious then make a plan with the group to get to a nearby village 
or road from where a taxi can be called to get you back to your car or home.
</p>
<h2>What if I've just had enough?</h2>
<p>
Discuss with the group whether there are options for shortening the route. If everyone else wants to continue with the planned 
route then you are free to leave the group and make your own way back.
</p>
</full-page>
</template>

<script>

import FullPage from '@/components/page/FullPage';

export default {
    name: 'AboutPage',
    components: {
        FullPage
    },
};
</script>

<style scoped>

p {
    text-align: left;
    margin: 10px;
    font-size: 1.5rem;
}

h2 {
    margin: 10px;
    text-align: left;
    font-size: 2.0rem;
}
</style>