<template>
    <full-page title="Surrey Hills Running Club">
    <div class="home-page">
        <div class="home-page-text">
        <p>
        Welcome to the Surrey Hills Running Club. We are a friendly group of runners who enjoy exploring the trails of the Surrey Hills. 
        We run every Sunday morning, with a different route every week. Routes are of various lengths, but usually at least 13 miles and tend to be quite hilly.
        Please read the <router-link to="/about" class="navigation-link">about our runs</router-link> page for more information on what to expect.
        </p>
        <p>
        We also sometimes travel further afield - anywhere with hills! The <router-link to="/past-events" class="navigation-link">past events page</router-link> contains details of some recent adventures.
        </p>
        <p>
        The club is not affiliated to a sporting body and there are no membership fees - just turn up and run.     
        </p>
        <!--p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p>
        <p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p>
        <p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p>
        <p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p>
        <p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p>
        <p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p>
        <p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p>
        <p>lots more text needed... lots more text needed... lots more text needed... lots more text needed... lots more text needed... </p-->
        </div>        

        <div class="home-page-images">
            <img src="img/shrc-1.jpg" class="image image-1">
            <img src="img/shrc-2.jpg" class="image image-2">
            <img src="img/shrc-4.jpg" class="image image-3">
            <img src="img/shrc-3.jpg" class="image image-4">
        </div>        
    </div>
    </full-page>
</template>

<script>

import FullPage from '@/components/page/FullPage';

export default {
    name: 'HomePage',
    components: {
        FullPage
    },
};
</script>

<style scoped>

p {
    text-align: left;
    margin: 10px;
    font-size: 1.5rem;
}

h2 {
    margin: 10px;
    text-align: left;
    font-size: 2.5rem;
}

.home-page {

}

.home-page-text {
    position: relative;
}
.home-page-images {
    /* flex: 1 1; */
    position: relative;
    height: 800px;
}

.image {
    width: 36rem;
    position: absolute;
    top: 0%;
    left: 50%;
    box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
    border: 0.1rem solid gray;
}

.image-1 {
    transform: translate(-20%, 0%);
    z-index: 0;
}

.image-2 {
    transform: translate(-70%, 60%);
    z-index: 1;
}

.image-3 {
    transform: translate(0%, 120%);
    z-index: 0;
}

.image-4 {
    transform: translate(-40%, 180%);
    z-index: 1;
}
</style>